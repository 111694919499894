<template>
  <div>
    <page-header-wrapper>
      <div class="work-place">
        <a-row :gutter="24">
          <a-col
            :xl="8"
            :lg="24"
            :md="24"
            :sm="24"
            :xs="24"
            class="col"
            style="padding-left: 20px; padding-right: 20px"
          >
            <!--  :height="270" -->
            <card-ex title="期间累计智驾里程">
              <miles :yearOdo="yearOdo" :loading="loading" :beginDate="beginDate" :endDate="endDate" />
            </card-ex>
            <!-- <a-col :xl="8" :lg="24" :md="24" :sm="24" :xs="24" class="col" style="padding-left: 20px; margin-top: 20px"> -->
            <card-ex
              title="月MPD"
              style="margin-top: 20px"
              :isEmpty="!vehicleDataMonth || vehicleDataMonth.length === 0"
              :height="360"
            >
              <MonthMPD :params="vehicleDataMonth" :resize="resize" />
            </card-ex>
            <!-- </a-col> -->
          </a-col>
          <a-col :xl="8" :lg="24" :md="24" :sm="24" :xs="24" style="padding-left: 0; padding-right: 0" class="col">
            <card-ex
              title="每周智驾里程及运行时长"
              :isEmpty="!vehicleDataWeek || vehicleDataWeek.length === 0"
              :height="360"
            >
              <week-miles-time :params="vehicleDataWeek" :resize="resize" :loading="loading" />
            </card-ex>
            <card-ex
              title="每周活跃的智驾车数量"
              :isEmpty="!vehicleDataWeek || vehicleDataWeek.length === 0"
              :height="360"
              :style="{ marginTop: defautMargin }"
            >
              <week-vehicle-active-count :params="vehicleDataWeek" :resize="resize" />
            </card-ex>
          </a-col>
          <a-col :xl="8" :lg="24" :md="24" :sm="24" :xs="24" class="col" style="padding-left: 20px">
            <card-ex
              title="每周平均无接管运行公里数"
              :isEmpty="!vehicleDataWeek || vehicleDataWeek.length === 0"
              :height="360"
            >
              <week-run-miles :params="vehicleDataWeek" :resize="resize" />
            </card-ex>
            <card-ex
              title="接管记录"
              :isEmpty="!vehicleEventData || vehicleEventData.length === 0"
              :height="360"
              :style="{ marginTop: defautMargin }"
            >
              <take-over-record :params="vehicleEventData" :loading="loading" :height="360" />
            </card-ex>
          </a-col>
        </a-row>
        <a-row>
          <card-ex title="于万智驾车足迹" :height="innerHeight" :style="{ marginTop: defautMargin }">
            <foot-mark :params="vehicleCityData" :resize="resize" />
          </card-ex>
        </a-row>
      </div>
    </page-header-wrapper>
    <div class="work-place-setup">
      <span
        @click="showDefaultConfigModal = true"
        v-hasPermi="['tenant:iot:vehicle:dashboards:edit']"
      >修改默认统计范围</span
      >
      <a-divider type="vertical" v-hasPermi="['tenant:iot:vehicle:dashboards:edit']" />
      <span @click="handleSetup">设置</span>
    </div>
    <vxe-modal
      v-model="showModal"
      title="智驾车辆dashboard设置"
      width="30%"
      height="90%"
      class="my-modal"
      @close="handleCloseLeftModal"
      show-zoom
      resize
      :lock-view="false"
      :mask="false"
      :position="{ top: '5%', left: '40%' }"
    >
      <Search
        :cacheKey="cacheKey"
        :mode="mode"
        :fromDate="beginDate"
        :toDate="endDate"
        :treeData="treeData"
        :vehicleIds="vehicleIds"
        @ok="handleSearch"
        @cancel="showModal = false"
      ></Search>
    </vxe-modal>

    <vxe-modal
      v-model="showDefaultConfigModal"
      title="智驾车辆dashboard默认设置"
      width="30%"
      height="96%"
      class="my-modal"
      @close="showDefaultConfigModal = false"
      show-zoom
      resize
      :lock-view="false"
      :mask="false"
      :position="{ top: '2%', left: '40%' }"
    >
      <default-config @ok="handleSearch2" @cancel="showDefaultConfigModal = false"></default-config>
    </vxe-modal>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import Miles from './miles'
import WeekMilesTime from './weekMilesTime'
import WeekVehicleActiveCount from './weekVehicleActiveCount'
import HistoryTrack from './historyTrack'
import EffectMap from './effectMap'
import WeekRunMiles from './weekRunMiles'
import MonthMPD from './monthMPD'
import TakeOverRecord from './takeOverRecord'
import { getDefaultConfig, getDashboardsV2 } from '@/api/iot/vehicle'
import Search from './search'
import CardEx from './card-ex'
import defaultConfig from './defaultConfig'
import FootMark from './footmark'
export default {
  components: {
    Miles,
    WeekMilesTime,
    WeekVehicleActiveCount,
    HistoryTrack,
    EffectMap,
    WeekRunMiles,
    TakeOverRecord,
    Search,
    CardEx,
    defaultConfig,
    FootMark,
    MonthMPD
  },
  data() {
    return {
      defautMargin: '20px',
      yearOdo: 0,
      vehicleDataWeek: [],
      vehicleDataMonth: [],
      vehicleTaskData: [],
      vehicleEventData: [],
      vehicleCityData: [],
      updateCount: 0,
      resize: 0,
      loading: true,
      showModal: false,
      showDefaultConfigModal: false,
      beginDate: '',
      endDate: '',
      treeData: [],
      vehicleIds: [],
      cacheKey: 'vehicleDashboardTree',
      mode: 1,
      innerHeight: 0
    }
  },
  computed: {
    ...mapState({
      sideCollapsed: (state) => state.app.sideCollapsed
    })
  },
  watch: {
    sideCollapsed(newVal, oldVal) {
      console.log('resize')
      setTimeout(() => {
        this.resize++
      }, 500)
    }
  },
  mounted() {
    this.innerHeight = window.innerHeight
    window.addEventListener('resize', () => {
      this.innerHeight = window.innerHeight - 50
    })
    this.getConfig(this.getData)
    this.handleResize()
  },
  methods: {
    async getConfig(fn) {
      await getDefaultConfig().then((res) => {
        const data = res.data
        this.beginDate = data.beginDate
        this.endDate = data.endDate
        this.treeData = data.tree
        this.vehicleIds = data.vehicleIds
        this.mode = data.mode
        if (this.mode === 1) {
          this.endDate = null
        }
        this.setDefaultCheckData()
        fn && fn()
      })
    },
    setDefaultCheckData() {
      const hostname = location.hostname
      let tenantCode = 'yw'
      if (hostname.split('.').length === 4) {
        tenantCode = hostname.split('.')[0]
      }
      const storeKey = tenantCode + '-' + this.cacheKey + '-lastCheckedInfo'
      // 记忆上一次退出的数据
      if (!this.cacheKey) {
        return
      }
      const lastCheckedInfo = {
        currentCheckedKeys: this.vehicleIds.map((p) => 't-1-' + p)
      }
      localStorage.setItem(storeKey, JSON.stringify(lastCheckedInfo))
    },
    handleResize() {
      window.onresize = () => {
        this.resize++
        console.log('resize')
      }
    },
    getData(searchMode = 1) {
      console.log('searchMode', searchMode)
      this.loading = true
      const params = {
        beginDate: this.beginDate,
        endDate: this.endDate,
        vehicleIds: this.vehicleIds,
        searchMode: searchMode
      }
      getDashboardsV2(params).then((res) => {
        console.log('res', res)
        this.loading = false
        if (res.data) {
          const data = res.data
          this.yearOdo = data.yearOdo
          this.vehicleDataWeek = data.vehicleDataWeek
          this.vehicleDataMonth = data.vehicleDataMonth
          this.vehicleTaskData = data.vehicleTaskData
          this.vehicleEventData = data.vehicleEventData
          this.vehicleCityData = data.vehicleCityData
        }
      })
    },
    handleSearch(beginDate, endDate, vehicleIds) {
      this.showModal = false
      console.log('handleSearch')
      this.beginDate = beginDate
      this.endDate = endDate
      this.vehicleIds = vehicleIds
      this.getData(0)
    },
    handleSearch2(beginDate, endDate, vehicleIds) {
      this.showDefaultConfigModal = false
      console.log('handleSearch')
      this.beginDate = beginDate
      this.endDate = endDate
      this.vehicleIds = vehicleIds
      this.getConfig(this.getData)
    },
    handleSetup() {
      this.showModal = true
    }
  }
}
</script>
<style lang="less" scoped>
.work-place {
  padding-top: 10px;
}
.work-place-setup {
  position: absolute;
  right: 0;
  top: 30px;
  cursor: pointer;
  color: #3586ff;
}
</style>
